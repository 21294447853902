exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-section-a-propos-js": () => import("./../../../src/pages/section/a-propos.js" /* webpackChunkName: "component---src-pages-section-a-propos-js" */),
  "component---src-pages-section-accueil-js": () => import("./../../../src/pages/section/accueil.js" /* webpackChunkName: "component---src-pages-section-accueil-js" */),
  "component---src-pages-section-beneficiaires-js": () => import("./../../../src/pages/section/beneficiaires.js" /* webpackChunkName: "component---src-pages-section-beneficiaires-js" */),
  "component---src-pages-section-benevoles-js": () => import("./../../../src/pages/section/benevoles.js" /* webpackChunkName: "component---src-pages-section-benevoles-js" */),
  "component---src-pages-section-dons-js": () => import("./../../../src/pages/section/dons.js" /* webpackChunkName: "component---src-pages-section-dons-js" */),
  "component---src-pages-section-presse-js": () => import("./../../../src/pages/section/presse.js" /* webpackChunkName: "component---src-pages-section-presse-js" */)
}

